import React, { useState } from 'react';
import { Calendar as BigCalendar, momentLocalizer } from 'react-big-calendar';
import moment from "moment-timezone";
import CustomToolbar from '../CustomToolbar/CustomToolbar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import './Calendar.css';

const localizer = momentLocalizer(moment);

const regionColors = {
  light: {
    AMAS: '#3498db',
    EMEA: '#226124',
    APAC: '#9b59b6',
    default: '#95a5a6',
  },
  dark: {
    AMAS: '#3498db',
    EMEA: '#226124',
    APAC: '#9b59b6',
    default: '#7f8c8d',
  }
};

const Calendar = ({ events, onEventClick, isDarkMode }) => {
  const [view, setView] = useState('month');

  const eventStyleGetter = (event) => {
    const colors = isDarkMode ? regionColors.dark : regionColors.light;
    const backgroundColor = colors[event.region] || colors.default;
    return {
      style: {
        backgroundColor,
        borderRadius: '4px',
        opacity: 0.8,
        color: '#fff',
        border: 'none',
        display: 'block',
        fontSize: '0.8em',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        padding: '2px 4px',
      }
    };
  };

  return (
    <div className="calendar-wrapper">
      <BigCalendar
        localizer={localizer}
        events={events}
        startAccessor="start"
        endAccessor="end"
        views={['month', 'week', 'day']}
        view={view}
        onView={setView}
        onSelectEvent={onEventClick}
        eventPropGetter={eventStyleGetter}
        components={{
          toolbar: (props) => <CustomToolbar {...props} isDarkMode={isDarkMode} />,
        }}
        formats={{ eventTimeRangeFormat: () => null }}
        className={isDarkMode ? 'dark-calendar' : ''}
      />
    </div>
  );
};

export default Calendar;