import React from 'react';
import './Footer.css';

const Footer = () => {
  return (
    <footer className="app-footer">
      <p>If you find this useful, consider buying me a <a href="https://ko-fi.com/dreamlinear" target="_blank" rel="noopener noreferrer">coffee</a>!</p>
      <p>Not affiliated to but powered by <a href="https://www.vatsim.net/" target="_blank" rel="noopener noreferrer">VATSIM</a></p>
    </footer>
  );
};

export default Footer;