import React from 'react';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import './CustomToolbar.css';

const CustomToolbar = ({ onNavigate, onView, label, view, isDarkMode }) => (
  <div className={`toolbar ${isDarkMode ? 'dark' : ''}`}>
    <div className="toolbar-nav">
      <button onClick={() => onNavigate('PREV')} className="nav-button">
        <ChevronLeft size={20} />
      </button>
      <span className="toolbar-label">{label}</span>
      <button onClick={() => onNavigate('NEXT')} className="nav-button">
        <ChevronRight size={20} />
      </button>
    </div>
    <div className="toolbar-views">
      <button
        onClick={() => onView('month')}
        className={`view-button ${view === 'month' ? 'active' : ''}`}
      >
        Month
      </button>
      <button
        onClick={() => onView('week')}
        className={`view-button ${view === 'week' ? 'active' : ''}`}
      >
        Week
      </button>
      <button
        onClick={() => onView('day')}
        className={`view-button ${view === 'day' ? 'active' : ''}`}
      >
        Day
      </button>
    </div>
    <button onClick={() => onNavigate('TODAY')} className="today-button">
      Today
    </button>
  </div>
);

export default CustomToolbar;