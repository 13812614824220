import React from 'react';
import { Sun, Moon, CalendarHeart } from 'lucide-react';
import './Header.css';

const Header = ({ selectedRegion, regions, onRegionChange, isDarkMode, onToggleDarkMode }) => {
  return (
    <header className="app-header">
      <div className="header-title">
        <h1>VATCAL</h1>
        <p className="header-slogan">A calendar view of <a href="https://vatsim.net/events/" target='_blank'>vatsim events</a></p>
      </div>
      <div className="header-controls">
        <select
          value={selectedRegion}
          onChange={(e) => onRegionChange(e.target.value)}
          className="region-select"
        >
          {regions.map(region => (
            <option key={region} value={region}>{region}</option>
          ))}
        </select>
        <button onClick={onToggleDarkMode} className="dark-mode-toggle">
          {isDarkMode ? <Sun size={20} /> : <Moon size={20} />}
        </button>
      </div>
    </header>
  );
};

export default Header;