import React from 'react';
import Modal from 'react-modal';
import { ExternalLink } from 'lucide-react';
import moment from 'moment-timezone';
import './EventModal.css';

const EventModal = ({ event, isOpen, onClose, isDarkMode }) => {
  if (!event) return null;

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="Event Details"
      className={`modal ${isDarkMode ? 'dark' : ''}`}
      overlayClassName={`overlay ${isDarkMode ? 'dark' : ''}`}
    >
      <div className="modal-content">
        {event.banner && (
          <div className="modal-banner" style={{backgroundImage: `url(${event.banner})`}}>
            <div className="banner-gradient"></div>
          </div>
        )}
        <div className="modal-body">
          <h2 className="modal-title">{event.title}</h2>
          <p className="event-description">{event.short_description}</p>
          <div className="event-details">
            <div className="event-detail">
              <strong>Starts:</strong> 
              <span>{moment.utc(event.start_time).format("YYYY-MM-DD HH:mm")}Z</span>
            </div>
            <div className="event-detail">
              <strong>Ends:</strong> 
              <span>{moment.utc(event.end_time).format("YYYY-MM-DD HH:mm")}Z</span>
            </div>
            <div className="event-detail">
              <strong>Region:</strong> 
              <span>{event.region}</span>
            </div>
          </div>
          <div className="airport-list">
            <strong>Airports:</strong>
            <div className="airport-badges">
              {event.airports.map(airport => (
                <span key={airport.icao} className="airport-badge">
                  {airport.icao}
                </span>
              ))}
            </div>
          </div>
          <div className="modal-actions">
            <a 
              href={event.link} 
              target="_blank" 
              rel="noopener noreferrer" 
              className="vatsim-link"
            >
              View on VATSIM <ExternalLink size={18} className="external-link-icon" />
            </a>
            <button onClick={onClose} className="close-button">Close</button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default EventModal;