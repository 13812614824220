import React, { useState, useEffect } from 'react';
import Calendar from './components/Calendar/Calendar';
import Header from './components/Header/Header';
import EventModal from './components/EventModal/EventModal';
import Footer from './components/Footer/Footer';
import Modal from 'react-modal';
import moment from "moment-timezone";
import { initGA, logPageView } from './analytics';
import './App.css';

moment.tz.setDefault('Etc/Greenwich');
Modal.setAppElement('#root');

const GA_MEASUREMENT_ID = 'G-TQERXT8VTY';

const App = () => {
  const [events, setEvents] = useState([]);
  const [filteredEvents, setFilteredEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [regions, setRegions] = useState(['All']);
  const [selectedRegion, setSelectedRegion] = useState('All');
  const [isDarkMode, setIsDarkMode] = useState(false);

  useEffect(() => {
    initGA(GA_MEASUREMENT_ID);
    logPageView();
  }, []);

  useEffect(() => {
    fetch('https://vatapi.vercel.app/data/events')
      .then(response => response.json())
      .then(data => {
        const formattedEvents = data.data.map(event => ({
          title: event.name,
          start: new Date(event.start_time),
          end: new Date(event.end_time),
          region: event.organisers[0]?.region || 'Unknown',
          ...event
        }));
        setEvents(formattedEvents);
        setFilteredEvents(formattedEvents);

        const uniqueRegions = ['All', ...new Set(formattedEvents.map(event => event.region))];
        setRegions(uniqueRegions);
      });
  }, []);

  useEffect(() => {
    if (selectedRegion === 'All') {
      setFilteredEvents(events);
    } else {
      const filtered = events.filter(event => event.region === selectedRegion);
      setFilteredEvents(filtered);
    }
  }, [selectedRegion, events]);

  useEffect(() => {
    document.body.classList.toggle('dark', isDarkMode);
  }, [isDarkMode]);

  const handleEventClick = (event) => {
    setSelectedEvent(event);
  };

  const closeModal = () => {
    setSelectedEvent(null);
  };

  const toggleDarkMode = () => {
    setIsDarkMode(!isDarkMode);
  };

  return (
    <div className={`app-container ${isDarkMode ? 'dark' : ''}`}>
      <Header
        selectedRegion={selectedRegion}
        regions={regions}
        onRegionChange={setSelectedRegion}
        isDarkMode={isDarkMode}
        onToggleDarkMode={toggleDarkMode}
      />
      <Calendar
        events={filteredEvents}
        onEventClick={handleEventClick}
        isDarkMode={isDarkMode}
      />
      <Footer />
      <EventModal
        event={selectedEvent}
        isOpen={!!selectedEvent}
        onClose={closeModal}
        isDarkMode={isDarkMode}
      />
    </div>
  );
};

export default App;